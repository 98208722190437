import React from 'react'

const HB = () => {
  return (
    <div>
      <p>Go to</p>
      <p>anagram-anagram's-anagram-anagram-anagram</p>
    </div>
  )
}

export default HB
